import React from 'react';
import Navbar from './components/Navbar';

function TBD() {
    return (
        <div className="App">
            <Navbar/>
            <div className="App-details">
                <p className="App-section-title">Coming soon.</p>
            </div>
        </div>
    )
}

export default TBD;