import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './routes/Home';
import Story from './routes/Story';
// import Schedule from './routes/Schedule';
// import RSVP from './routes/RSVP';
// import Travel from './routes/Travel';
// import WeddingParty from './routes/WeddingParty';
// import Registry from './routes/Registry';
// import FAQ from './routes/FAQ';
import TBD from './routes/TBD';
// import Map from './routes/Map';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/">
          <Route index element={<Home/>}/>
          <Route path="story" element={<Story/>}/>
          <Route path="schedule" element={<TBD/>}/>
          <Route path="RSVP" element={<TBD/>}/>
          <Route path="travel" element={<TBD/>}/>
          <Route path="weddingparty" element={<TBD/>}/>
          <Route path="registry" element={<TBD/>}/>
          <Route path="FAQ" element={<TBD/>}/>
          {/* <Route path="map" element={<Map/>}/> */}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
