import React from 'react';
import coverPhoto from '../images/official-engagement-photo.jpg';
import Navbar from './components/Navbar';
import Divider from './components/Divider';

function Home() {
    return (
        <div className="App">
            <Navbar/>
            <img src={coverPhoto} className="img-center" alt="engagement in Kyoto"/>
            <text>Photo by <a href="https://www.reypaulweddings.com/" target="_blank" rel="noopener noreferrer">Rey Paul Weddings</a></text>
            <Divider/>
            <div className="App-details">
                <p className="App-details-title">Friday, May 16, 2025</p>
                <p className="App-details-header">Glen Head, NY, USA</p>
                <p>Hi there, we're excited to share our special day with you and will be providing more details soon!</p>
            </div>
        </div>
    )
}

export default Home;