import React from 'react';
import storyPhoto from '../images/school-library-building.jpg';
import japanPhoto from '../images/first-engagement-photo.jpg';
import Navbar from './components/Navbar';
import Divider from './components/Divider';

function Story() {
    return (
        <div className="App">
            <Navbar/>
            <img src={japanPhoto} className="img-left" alt="engagement in Kyoto"/>
            <Divider/>
            <div className="App-details">
                {/* Story Section */}
                <p className="App-section-title">Story</p>
                {/* Story Details */}
                <p>The first time we met was during the Spring semester of college in sci-fi class. We didn't really talk to each other until the professor put us in the same team for a group presentation. From then on we were aware of each other's presence and started hanging out more after class. Eventually we joined the computer science club and had adventures together with our friends.</p>
                <img src={storyPhoto} className="img-center" alt="school library building"/>
                {/* About Us Sub-Section */}
                <p className="App-section-title">About Us</p>
                {/* About Us #1 */}
                <p>We're on the same page about <bold>family values</bold>.</p>
                {/* About Us #2 */}
                <p>We're regulars at <bold>Prince Tea House</bold> :3c</p>
                {/* About Us #3 */}
                <p>Our favorite activity together is <bold>watching anime, shows, movies, etc.</bold></p>
                {/* About Us #4 */}
                <p>Our most memorable trip together is <bold>our trip to Japan in March 2024</bold>.</p>
                {/* About Us #5 */}
                <p>For our honeymoon, we'll most likely plan to <bold>go back to Japan</bold> :)</p>
                {/* About Us #6 */}
                <p>Our relationship "ship" name is <bold>Lindris</bold>.</p>
            </div>
        </div>
    )
}

export default Story;