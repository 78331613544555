import React from 'react';
import { NavLink } from 'react-router-dom';
import menuIcon from '../../images/dropdown-menu-icon.png';

function Navbar() {
    return (
        <header className="App-header">
            <h1>Linda & Chris</h1>
            <nav className="Nav">
                <button className="Nav-menu"><img src={menuIcon} alt="hamburger menu icon"/>Menu</button>
                <div className="Nav-content">
                    <NavLink to="/"><p>Home</p></NavLink>
                    <NavLink to="/story"><p>Story</p></NavLink>
                    <NavLink to="/schedule"><p>Schedule</p></NavLink>
                    <NavLink to="/travel"><p>Travel</p></NavLink>
                    <NavLink to="/weddingparty"><p>Wedding Party</p></NavLink>
                    <NavLink to="/RSVP"><p>RSVP</p></NavLink>
                    <NavLink to="/registry"><p>Registry</p></NavLink>
                    <NavLink to="/FAQ"><p>Guest FAQ</p></NavLink>
                </div>
            </nav>
        </header>
    )
}

export default Navbar;