import React from 'react';
import divider from '../../images/cherry-blossom-divider.png';

function Divider() {
    return (
        <div className="Divider">
            <img src={divider} className="Divider" alt="cherry blossom divider"/>
        </div>
    )
}

export default Divider;